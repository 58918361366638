import React from 'react';
import { Popover } from '@mui/material';

const TitleInfoPopover = ({
  infoComponent,
  open,
  anchorEl,
  onClose
}: {
  infoComponent: React.ReactNode;
  open: boolean;
  anchorEl: any;
  onClose: () => void;
}) => {
  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      sx={{ '& .MuiPaper-root': { bgcolor: 'transparent' } }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}>
      {infoComponent}
    </Popover>
  );
};

export default React.memo(TitleInfoPopover);
