import getDateString from '../../../utils/getDateString';

interface ApprovalHistoryEntry {
  submission_type: string;
  submission_num: string;
  description?: string;
  category_bucket?: string;
  approval_date?: string;
}

const getPrefix = (entry: ApprovalHistoryEntry) => {
  return `${entry.submission_type}-${entry.submission_num}`;
};

const getApprovalDateAndNum = (entry: ApprovalHistoryEntry) => {
  let result = getDateString(entry.approval_date as string);
  if (entry.submission_num) {
    result += ` - ${entry.submission_num}`;
  }
  return result;
};

const getSubmissionName = (entry: ApprovalHistoryEntry, source: string) => {
  const approvalDate = getDateString(entry.approval_date as string);

  switch (source) {
    case 'us':
      return entry.description ? `${getPrefix(entry)}: ${entry.description}` : getPrefix(entry);

    case 'jp':
      if (!entry.submission_type) return getApprovalDateAndNum(entry);
      return `${approvalDate} - ${entry.submission_type}${
        entry.submission_num ? `-${entry.submission_num}` : ''
      }`;

    case 'hpra':
    case 'eu':
      return `${approvalDate} - ${getPrefix(entry)}`;

    case 'hma':
      return entry.category_bucket
        ? `${approvalDate} - ${entry.category_bucket}-${entry.submission_num}`
        : getApprovalDateAndNum(entry);

    case 'ca':
      return entry.submission_type && entry.submission_num
        ? `${approvalDate} - ${getPrefix(entry)}`
        : getApprovalDateAndNum(entry);

    default:
      return getPrefix(entry);
  }
};

export default getSubmissionName;
