import React, { useMemo, useState } from 'react';
import { debounce } from 'lodash';

import {
  Box,
  Divider,
  IconButton,
  InputAdornment,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  Drawer,
  Tooltip
} from '@mui/material';
import {
  CloseCrossIcon,
  AngleDownIcon,
  SearchIcon,
  InfoIconSummary,
  ArrowLeftIcon,
  CustomCheckIcon
} from '../../../assets/svgs/Icons';

import TitleInfoPopover from './components/TitleInfoPopover';
import Metadata from './components/EntityMetadata';

import styles from './Drawer.styles';
import DocumentAccordion from './components/DocumentAccordion';
import SecondaryDrawer from './components/SecondaryDrawer';
import DocumentViewer from './components/DocumentViewer';
import { DocumentListDialogProps } from './types';
import { filterByCategory, filterBySearchText } from './filterUtils';

const DEFAULT_DOCUMENT_CATEGORY = [
  {
    name: 'All Files',
    count: 0,
    id: 'all'
  }
];

const DocumentListDialog = ({
  defaultDocuments,
  title,
  infoComponent,
  onClose,
  chatRIAMetadata,
  source,
  defaultSelectedEntityId,
  subEntities = []
}: DocumentListDialogProps) => {
  const [infoElement, setInfoElement] = useState(null);
  const [selectedCategoryId, setSelectedCategoryId] = useState('all');

  const [openSecondaryDrawer, setOpenSecondaryDrawer] = useState<boolean>(false);
  const [selectedDocument, setSelectedDocument] = useState<any>(null);
  const [chatRIADocumentMetadata, setChatRIADocumentMetadata] = useState<any>(null);

  const [selectedEntityId, setSelectedEntityId] = useState<any>(defaultSelectedEntityId);
  const [documents, setDocuments] = useState<any>(defaultDocuments);

  const [searchText, setSearchText] = useState<any>('');

  const handleDocumentSelection = (document: any) => {
    setSelectedDocument(document);
    setChatRIADocumentMetadata({ ...chatRIAMetadata, category: document.category });
  };

  const handleEntitySelection = (entity: any) => {
    setSelectedDocument(null);
    setSelectedCategoryId('all');
    setDocuments(entity.getDocuments?.() || []);
    setSelectedEntityId(entity.id);
  };

  const selectedEntity = useMemo(() => {
    return subEntities?.find((entity: any) => entity.id === selectedEntityId);
  }, [selectedEntityId]);

  const documentCategories = useMemo(() => {
    let totalCount = 0;
    const categories = documents?.map((doc: any) => {
      totalCount += doc.documents?.length || 0;
      return {
        id: doc.id,
        name: doc.categoryTitle,
        count: doc.documents?.length || 0
      };
    });
    categories.unshift({
      ...DEFAULT_DOCUMENT_CATEGORY[0],
      count: totalCount
    });
    return categories;
  }, [documents]);

  const handleSearchTextChange = debounce((event: any) => setSearchText(event.target.value), 200);

  const documentsToDisplay = useMemo(() => {
    let docs = filterByCategory(documents, selectedCategoryId);
    docs = filterBySearchText(docs, searchText);
    return docs;
  }, [documents, selectedCategoryId, searchText]);

  return (
    <Drawer open anchor='right' sx={styles.mainDrawer} onClose={onClose}>
      <Box bgcolor='primary.600' py={1.5} px={2.5}>
        <Stack direction='row' alignItems='center'>
          <Tooltip title={title}>
            <Typography sx={styles.drawerTitle}>{title}</Typography>
          </Tooltip>

          {infoComponent && (
            <IconButton
              sx={{ p: 0.75, ml: 1 }}
              onClick={(event: any) => setInfoElement(event.currentTarget)}>
              <InfoIconSummary sx={styles.drawerHeaderIcon} />
            </IconButton>
          )}
          <IconButton sx={{ ml: 'auto', p: 0.75, mr: -0.75 }} onClick={onClose}>
            <CloseCrossIcon sx={styles.drawerHeaderIcon} />
          </IconButton>
        </Stack>
        <TitleInfoPopover
          open={!!infoElement}
          anchorEl={infoElement}
          infoComponent={infoComponent}
          onClose={() => setInfoElement(null)}
        />
      </Box>
      <Box height='100%'>
        <Box px={2.5} py={1.5}>
          <Stack direction='row' alignItems='center'>
            <Box
              display='flex'
              alignItems='center'
              sx={{ cursor: 'pointer' }}
              onClick={() => setOpenSecondaryDrawer(true)}
              maxWidth='30%'>
              <ArrowLeftIcon sx={{ color: 'gray.900', fontSize: 14, mr: 0.5 }} />
              <Tooltip title={selectedEntity?.name}>
                <Typography
                  color='gray.900'
                  fontWeight={700}
                  fontSize={14}
                  lineHeight={1.5}
                  overflow='hidden'
                  textOverflow='ellipsis'
                  mt={0.25}
                  sx={{ textWrap: 'nowrap' }}>
                  {selectedEntity?.name}
                </Typography>
              </Tooltip>
            </Box>
            {selectedEntity?.metadata && selectedEntity?.metadata?.length > 0 && (
              <>
                <Divider
                  orientation='vertical'
                  sx={{ height: 16, mx: 1.5, borderColor: 'gray.400' }}
                />
                <Metadata data={selectedEntity?.metadata} />
              </>
            )}
          </Stack>
        </Box>
        <Divider sx={{ borderColor: 'gray.200' }} />
        <Box px={2.5} py={2}>
          <Stack direction='row' alignItems='center'>
            <Select
              value={selectedCategoryId}
              sx={styles.categorySelection}
              onChange={(event: any) => setSelectedCategoryId(event.target.value)}
              IconComponent={AngleDownIcon}>
              {documentCategories.map((doc: any) => (
                <MenuItem
                  value={doc.id}
                  key={doc.id}
                  sx={{
                    '& .Mui-selected': {
                      bgcolor: 'primary.0'
                    }
                  }}>
                  <ListItemText
                    sx={{
                      m: 0,
                      span:
                        selectedCategoryId === doc.id
                          ? {
                              fontSize: 13,
                              fontWeight: 700,
                              color: 'gray.800',
                              letterSpacing: 0.2
                            }
                          : {
                              fontSize: 13,
                              fontWeight: 400,
                              color: 'gray.800',
                              letterSpacing: 0.2
                            }
                    }}>
                    {doc.name} ({doc.count})
                  </ListItemText>
                  <ListItemIcon>
                    {selectedCategoryId === doc.id && (
                      <CustomCheckIcon sx={styles.secondaryDrawerListItemSelectedCheckbox} />
                    )}
                  </ListItemIcon>
                </MenuItem>
              ))}
            </Select>
            <TextField
              variant='outlined'
              placeholder='Search...'
              sx={styles.searchInputBox}
              onChange={handleSearchTextChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
            />
          </Stack>
        </Box>
        <Divider sx={{ mx: 2.5, borderColor: 'gray.200' }} />
        <Box px={2.5} py={2} sx={styles.accordionContainer}>
          {documentsToDisplay?.map((docGroup: any) => (
            <DocumentAccordion
              key={docGroup.id}
              title={docGroup.categoryTitle}
              items={docGroup.documents}
              selectedDocument={selectedDocument}
              onDocumentSelect={handleDocumentSelection}
            />
          ))}
          {!documentsToDisplay ||
            (documentsToDisplay.length === 0 && (
              <Box
                position='absolute'
                top='10%'
                left='50%'
                sx={{ transform: 'translate(-50%, 0)' }}>
                <Typography fontSize={14} fontWeight='700'>
                  No documents available.
                </Typography>
              </Box>
            ))}
        </Box>
      </Box>
      {openSecondaryDrawer && (
        <SecondaryDrawer
          entities={subEntities}
          selectedEntity={selectedEntityId}
          onClose={() => setOpenSecondaryDrawer(false)}
          onEntitySelect={handleEntitySelection}
        />
      )}
      {selectedDocument && chatRIADocumentMetadata && (
        <DocumentViewer
          title={selectedDocument.title}
          link={selectedDocument.title_link}
          chatRIAMetadata={chatRIADocumentMetadata}
          source={source}
          entityTitle={title}
          otherLanguages={selectedDocument.otherLanguages}
          onClose={() => {
            setSelectedDocument(null);
            setChatRIADocumentMetadata(null);
          }}
        />
      )}
    </Drawer>
  );
};

export default React.memo(DocumentListDialog);
