import {
  Box,
  Button,
  Grow,
  IconButton,
  SlideProps,
  Snackbar,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { Send, OpenInNew } from '@mui/icons-material';
import { isEmpty } from 'lodash';

import styles from './DocumentViewer.styles';
import PDFViewer from '../../../PDFViewer';
import { CloseIcon } from '../../../../assets/svgs/Icons';

// store
import GlobalStore from '../../../../store';
import GlobalActions from '../../../../store/actions';
import LanguageToggleButton from '../../../LanguageToggleComponents/LanguageToggleButton';
import { DocumentViewerProps } from '../types';

const SlideTransition = (props: SlideProps) => {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Grow {...props} />;
};
const DocumentViewer = ({
  title,
  chatRIAMetadata,
  link,
  onClose,
  source,
  entityTitle,
  otherLanguages
}: DocumentViewerProps) => {
  const { dispatch } = useContext(GlobalStore) as any;
  const { location } = window;
  const [originalTextEnabled, setOriginalTextEnabled] = useState<boolean>(false);
  const [pdfLink, setPdfLink] = useState<string>(link);

  // Reset the state if the pdf link changed from the parent
  useEffect(() => {
    setPdfLink(link);
    setOriginalTextEnabled(false);
  }, [link]);

  const handleChatRia = (pdfUrl: string) => {
    dispatch({
      type: GlobalActions.SET_CHATRIA_TRIGGERED_FROM,
      value: 'document'
    });
    dispatch({
      type: GlobalActions.SET_APPLICATION_SOURCE,
      value: source
    });
    dispatch({
      type: GlobalActions.SET_ARIA_DOCUMENT,
      value: {
        blob: pdfUrl,
        url: pdfUrl,
        item: chatRIAMetadata,
        source,
        triggerReopenChatRia: false
      }
    });
    dispatch({ type: GlobalActions.SET_APPLICATION_NAME, value: entityTitle });
    dispatch({ type: GlobalActions.SET_CHATRIA_OPEN, value: true });
  };

  const openPDFInNewTab = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const loc = new URL(link);
    if (!loc?.hostname?.includes('s3')) window.open(link, '_blank');
    else {
      const domain = new URL(location?.href)?.origin;
      const bucket = loc?.hostname?.split('.')[0];
      window.open(
        `${domain}/resource?bucket=${bucket}&filename=${loc?.pathname?.replace(/\//, '')}`,
        '_blank'
      );
    }
    e.stopPropagation();
  };

  const handleLanguageToggle = () => {
    if (!originalTextEnabled) {
      setPdfLink(otherLanguages?.[source].title_link as string);
    } else {
      setPdfLink(link);
    }
    setOriginalTextEnabled(!originalTextEnabled);
  };

  return (
    <Snackbar
      TransitionComponent={SlideTransition}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      sx={styles.container}
      open={!!link}>
      <Stack flexDirection='column' sx={styles.pdfViewer}>
        <Stack
          display='flex'
          flexDirection='row'
          alignItems='center'
          justifyContent='space-between'
          sx={{ p: 2, pt: 0, width: '100%' }}>
          <Stack spacing={1.5} direction='row' alignItems='center'>
            <Tooltip title={title}>
              <Typography sx={styles.title}>{title}</Typography>
            </Tooltip>
            <Tooltip title='Use Chat RIA to interact with the document'>
              <Button
                variant='text'
                disabled={!link}
                onClick={() => handleChatRia(link)}
                sx={styles.chatRiaButton}>
                Chat RIA
                <Send sx={styles.chatRiaIcon} />
              </Button>
            </Tooltip>
            {!isEmpty(otherLanguages) && (
              <LanguageToggleButton
                source={source}
                originalTextEnabled={originalTextEnabled}
                toggleTextLanugage={handleLanguageToggle}
                color='green'
              />
            )}
          </Stack>
          <Stack direction='row' alignItems='center' spacing={2.5}>
            <IconButton aria-label='close' onClick={openPDFInNewTab} sx={{ fontSize: 24, p: 0 }}>
              <OpenInNew fontSize='inherit' sx={{ color: 'gray.600' }} />
            </IconButton>
            <IconButton aria-label='close' onClick={onClose} sx={{ fontSize: 18, p: 0.75 }}>
              <CloseIcon fontSize='inherit' sx={{ color: 'gray.600' }} />
            </IconButton>
          </Stack>
        </Stack>
        <Box id='pdfBox'>
          <PDFViewer fileUrl={pdfLink} initialPage={0} defaultScale='PageWidth' />
        </Box>
      </Stack>
    </Snackbar>
  );
};

export default React.memo(DocumentViewer);
