/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { has } from 'lodash';

// mui
import { Box, Stack, Tooltip, Typography } from '@mui/material';

// Icons
import { UrlParameters } from '../../../types/reactRouterDom';
import DocumentIcon from '../../../Images/documentsIcon.svg';

import styles from './TimelineDocuments.styles';

import CustomTimelinePropsInterface from '../../../interfaces/CustomTimelinePropsInterface';
import {
  RESOURCE_MAPPPING_BAESD_ON_TABNAME,
  SOURCE_RESOURCE_MAPPPING
} from '../../../pages/RegulatoryInformation/ApprovalHistory/constants';
import useQuery from '../../../helpers/customHooks/queryParameter';
import { SnapshotPlayBtn } from '../../../assets/svgs/Icons';

import SnapshotLayout from '../../../pages/RegulatoryInformation/ApprovalHistory/SnapshotLayout';

// store
import RegulatoryInformationStore from '../../../store/RegulatoryInformation';
import getDocumentMetadata from './getDocumentMetadata';
import DocumentListing from '../../Dialogs/DocumentListing';

import Card from '../../../pages/SearchResults/components/Card';
import getDocuments from './getDocuments';
import getSubmissionName from './getSubmissionName';

// Need to change this completely
const TimelineDocuments: React.FC<CustomTimelinePropsInterface> = ({
  column,
  dataItem
}: CustomTimelinePropsInterface) => {
  const [open, setOpen] = useState<boolean>(false);
  const { source } = useParams<UrlParameters>();
  const query = useQuery();
  const { location } = window;
  // snapshot modal
  const [modalOpen, setModalOpen] = useState(false);

  const { regulatoryState } = useContext(RegulatoryInformationStore);
  const curTab = new URLSearchParams(location.search).get('tabname') ?? 'approvalHistory';

  const handleOpenPopup = (e: any, toOpen: boolean) => {
    e.preventDefault();
    if (!toOpen) {
      setOpen(true);
    }
  };

  const documents = useMemo(() => {
    return getDocuments(dataItem, source as string);
  }, [dataItem?.file_links, dataItem?.trials_in_scope]);

  const chatRIAMetadata = useMemo(
    () =>
      getDocumentMetadata(
        source as string,
        regulatoryState?.applicationDetails ?? {},
        dataItem,
        ''
      ),
    [dataItem, source, regulatoryState?.applicationDetails]
  );

  const subEntities =
    curTab === 'commissionProcedures'
      ? regulatoryState.commissionProceduresSubmissionDocuments
      : regulatoryState.submissionDocuments;

  const submissionSource = curTab === 'commissionProcedures' ? 'commissionProcedures' : source;

  if (column === undefined) {
    return <Box />;
  }

  return (
    <Box sx={styles.textAlignment}>
      <Stack
        direction={{
          xs: 'column',
          sm: 'column',
          md: 'column',
          lg: 'row',
          'lg-xl': 'row',
          xl: 'row'
        }}
        justifyContent='center'
        spacing={2}>
        <Box
          onClick={(e: React.SyntheticEvent) => handleOpenPopup(e, dataItem.document_count === 0)}>
          <Tooltip title={dataItem.document_count === 0 ? 'No Resources available' : ''}>
            <Stack direction='row' pl={2} spacing={0.5} sx={styles.linkItem}>
              <img src={DocumentIcon} alt='documentLogo' />{' '}
              <Stack direction='column'>
                <Typography sx={{ color: '#7879f1' }}>
                  {`${dataItem.document_count} ${
                    RESOURCE_MAPPPING_BAESD_ON_TABNAME.includes(query.get('tabname') || '')
                      ? 'Documents'
                      : SOURCE_RESOURCE_MAPPPING[source as string]
                  }`}
                </Typography>
                {dataItem?.trials_in_scope && (
                  <Typography sx={{ color: '#27ae60' }}>
                    {dataItem?.trials_in_scope.length} Trials
                  </Typography>
                )}
              </Stack>
            </Stack>
          </Tooltip>
        </Box>
        {dataItem && has(dataItem, 'snapshot') && dataItem?.is_snapshot && (
          <Stack alignItems='center' justifyContent='center'>
            <Stack
              onClick={() => setModalOpen(true)}
              direction='row'
              spacing='2px'
              alignItems='center'
              sx={styles.snapshotBtn}>
              <SnapshotPlayBtn sx={{ fontSize: 12 }} />
              <Typography variant='body1' sx={styles.snapshotBtnText}>
                Snapshot
              </Typography>
            </Stack>
          </Stack>
        )}
        {/* snapshot code starts here */}
        <SnapshotLayout
          onclose={() => setModalOpen(false)}
          dataItem={dataItem}
          modalOpen={modalOpen}
        />
        {/* snapshot code ends here */}
      </Stack>
      {open && (
        <DocumentListing
          defaultDocuments={documents}
          defaultSelectedEntityId={getSubmissionName(dataItem, submissionSource as string)}
          title={regulatoryState.applicationDetails?.title}
          onClose={() => setOpen(false)}
          chatRIAMetadata={chatRIAMetadata}
          source={source as string}
          subEntities={subEntities}
          infoComponent={
            <Card
              data={regulatoryState.applicationDetails}
              hideApplication={() => {}}
              triggerChatRIA={() => {}}
              setFavoriteInHook={() => {}}
              setInProjectInHook={() => {}}
              setSubscriptionInHook={() => {}}
              viewMode
            />
          }
        />
      )}
    </Box>
  );
};

export default React.memo(TimelineDocuments);
