import React from 'react';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  List,
  ListItem,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';
import { AngleDownIcon, DocumentSimpleIcon } from '../../../../assets/svgs/Icons';

import DocumentMetadata from './DocumentMetadata';

import styles from '../Drawer.styles';
import { DocumentAccordionProps } from '../types';

const DocumentAccordion = ({
  title,
  items,
  onDocumentSelect,
  selectedDocument
}: DocumentAccordionProps) => {
  return (
    <Accordion sx={styles.accordion} defaultExpanded>
      <AccordionSummary
        sx={styles.accordionSummary}
        expandIcon={<AngleDownIcon sx={{ fontSize: 12 }} />}
        aria-controls='panel-content'
        id='panel-header'>
        <Typography sx={styles.accordionSummaryTitle}>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails sx={styles.accordionDetails}>
        <List sx={{ p: 0 }}>
          {items.map((item: any, index: number) => (
            <ListItem
              key={`${item.title + index}`}
              sx={{
                ...styles.documentListItem,
                ...(index !== items.length - 1 && {
                  borderBottom: '1px solid gray',
                  borderColor: 'gray.200'
                })
              }}>
              <Stack direction='row' width='100%'>
                <DocumentSimpleIcon sx={styles.documentIcon} />
                <Box ml={1.5} overflow='hidden'>
                  <Tooltip title={item.title}>
                    <Typography
                      sx={{
                        ...(item.title_link
                          ? styles.clickableDocumentTitle
                          : styles.nonClickableDocumentTitle),
                        textDecoration:
                          selectedDocument?.title_link &&
                          selectedDocument?.title_link === item.title_link
                            ? 'underline'
                            : 'none'
                      }}
                      lineHeight={1.5}
                      onClick={() => item.title_link && onDocumentSelect?.(item)}>
                      {item.title}
                    </Typography>
                  </Tooltip>

                  {item.metadata && <DocumentMetadata data={item.metadata} />}
                  {item.actions && (
                    <Stack
                      direction='row'
                      alignItems='center'
                      flexWrap='wrap'
                      mt={0.5}
                      divider={<Divider orientation='vertical' sx={styles.verticalDivider} />}>
                      {item.actions.map((action: any) => (
                        <Typography
                          sx={styles.clickableDocumentTitle}
                          key={action.title_link}
                          onClick={() => action.title_link && onDocumentSelect?.(action)}>
                          {action.title}
                        </Typography>
                      ))}
                    </Stack>
                  )}
                </Box>
              </Stack>
            </ListItem>
          ))}
        </List>
      </AccordionDetails>
    </Accordion>
  );
};

export default React.memo(DocumentAccordion);
