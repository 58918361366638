const styles = {
  container: {
    right: `640px !important`,
    top: `50px !important`
  },
  pdfViewer: {
    width: 700,
    borderRadius: 5,
    height: '90vh',
    maxHeight: '99vh',
    mb: 10,
    background: 'white',
    justifyContent: 'center',
    alignItems: 'center',
    '& > div#pdfBox': { height: 'calc(100% - 80px)', width: 'calc(100% - 32px)' }
  },
  title: {
    fontSize: 14,
    fontWeight: 700,
    color: 'gray.800',
    textTransform: 'capitalize',
    maxWidth: '40%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textWrap: 'nowrap'
  },
  chatRiaButton: {
    display: 'flex',
    ml: '10px',
    width: 'fit-content',
    borderRadius: '16px',
    height: '32px',
    padding: '6px 16px',
    border: '1px solid',
    borderColor: 'secondary.700',
    backgroundColor: 'white.main',
    textTransform: 'capitalize',
    color: 'secondary.700',
    fontSize: '12px',
    fontWeight: '700'
  },
  chatRiaIcon: {
    fontSize: 16,
    p: '2.667px 2.666px 2.667px 2.667px',
    color: 'secondary.700'
  }
};

export default styles;
